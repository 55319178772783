import React from 'react'
import PageHelmet from '../components/PageHelmet'
import TopInterviewLarge from '../components/TopInterviewLarge'

const CvReviewPage = () => (
  <>
    <PageHelmet title="Get a free CV review" />
    <TopInterviewLarge style={{ width: '600px' }} />
  </>
)

export default CvReviewPage
